enum Top100Event {
  // - регистрация и логин
  POPUP_REG = 'pop-up-reg-btn',
  INTRO_LOGIN = 'login_button_intro', //
  INTRO_REG = 'reg_button_first_block', //
  CONNECT_REG = 'reg_button_sandbox_block', //
  BASE_REG = 'reg_button_knowledge_base_page', //
  PAYMENT_REG = 'reg_button_Payment_page', //
  PRICE_REG = 'reg_button_price_page', //

  // - ссылки в хедере
  NAV_MAIN = 'nav_link_main', //
  NAV_BASE = 'nav_link_knowledge_base', //
  NAV_PRICE = 'nav_link_price', //
  NAV_PAYMENT = 'nav_link_payment', //
  NAV_PROFILE = 'nav_link_profile',

  // - правовые документы
  LEGAL_OFFER = 'legal_link_offer', //
  LEGAL_PDATA = 'legal_link_pdata', //
  LEGAL_PRIVACY = 'legal_link_privacy', //
  LEGAL_TERMS = 'legal_link_terms-of-use', //

  // - ссылки на ресурсы базы знаний в попапе хедера
  BASE_HEADER_FAQ = 'base_header_link_faq', //
  BASE_HEADER_FAS = 'base_header_link_fas', //
  BASE_HEADER_VIDEO = 'base_header_link_video', //
  BASE_HEADER_DOCS = 'base_header_link_docs', //
  BASE_HEADER_TELEGRAM = 'base_header_link_telegram',
  BASE_HEADER_COMICS = 'base_header_link_comics', //

  // - ссылки на ресурсы базы знаний на странице базы знаний
  BASE_PAGE_FAQ = 'base_page_link_faq', //
  BASE_PAGE_FAS = 'base_page_link_fas', //
  BASE_PAGE_VIDEO = 'base_page_link_video', //
  BASE_PAGE_DOCS = 'base_page_link_docs', //
  BASE_PAGE_TELEGRAM = 'base_page_link_telegram', //
  BASE_PAGE_COMICS = 'base_page_link_comics', //

  // Профиль пользователя
  TAB_PROFILE = 'tab_profile', //
  TAB_ACCOUNT = 'tab_account', //
  TAB_DOCUMENTS = 'tab_documents', //
  TAB_PROFILE_LINK_SANDBOX = 'tab_profile_link_sandbox', //
  TAB_PROFILE_ACCESS_SANDBOX = 'tab_profile_access_sandbox', //
  TAB_PROFILE_LINK_PROD = 'tab_profile_link_prod', //
  TAB_PROFILE_ACCESS_PROD = 'tab_profile_access_prod', //

  // Получение доступа в лк
  SANDBOX_CREATE_CLIENT = 'sandbox_create_client', //
  SANDBOX_JOIN_CLIENT = 'sandbox_join_client', //
  PROD_CREATE_CLIENT = 'prod_create_client', //
  PROD_JOIN_CLIENT = 'prod_join_client', //
  PROD_SEND_DOCS = 'prod_send_docs', //

  // Документация
  SANDBOX_BLOCK_REG = 'sandbox_block_reg_button',
  SANDBOX_BLOCK_KBASE = 'sandbox_block_knowledge_base_link',
  SANDBOX_BLOCK_PRICE = 'sandbox_block_price_link',
  SANDBOX_BLOCK_DOC = 'sandbox_block_doc_link',
  API_BLOCK_DOC = 'api_block_api_doc_link',

  // footer
  FOOTER_LINK_KBASE = 'footer_link_knowledge_base_link',
  FOOTER_LINK_PRICE = 'footer_link_price_link',
  FOOTER_LINK_TG_CHANNEL = 'footer_link_tg_channel_link',
  FOOTER_LINK_TG_BOT = 'footer_link_tg_bot_link',
  FOOTER_LINK_PRIVACY = 'footer_link_legal_privacy_link',
  FOOTER_LINK_TERMS = 'footer_link_legal_terms_of_use_link',
}

export default Top100Event;
