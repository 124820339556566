import { useEffect } from 'react';

export const useInfoController = (metaData: {
  metaTitle?: string;
  metaDescription?: string;
  metaKeyWords?: string;
}) => {
  const { metaTitle, metaDescription, metaKeyWords } = metaData;
  return useEffect(() => {
    if (metaTitle && metaDescription && metaKeyWords) {
      document.title = metaTitle;
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', metaDescription);
      document
        .querySelector('meta[name="keywords"]')
        ?.setAttribute('content', metaKeyWords);
    }
  }, [metaTitle, metaDescription, metaKeyWords]);
};

export const useCrawlerController = () => {
  return useEffect(() => {
    if (window.location.origin !== 'https://ord-lab.ru') {
      const noIndex = document.createElement('meta');
      noIndex.setAttribute('name', 'robots');
      noIndex.setAttribute('content', 'noindex');
      document.head.appendChild(noIndex);
    }
  }, []);
};

export const useCanonical = () => {
  const { origin, pathname } = window.location;
  const canonicalLink = document.querySelector('link[rel="canonical"]');

  return useEffect(() => {
    canonicalLink?.setAttribute('href', origin + pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin, pathname]);
};
