import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { IBlock, IDocsTypes } from './types';

const getBaseUrl = () => {
  if (window.location.origin.includes('ord-lab.dev')) {
    return 'https://website.ord-lab.dev/doc-framework';
  }
  return 'https://ord-lab.ru/docs';
};

export const docsApi = createApi({
  reducerPath: 'docsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUrl(),
    credentials: 'include',
  }),
  tagTypes: ['Heads', 'Blocks', 'Rows', 'TextBlock', 'Cols', 'Docs'],
  endpoints: (builder) => ({
    getDocs: builder.query<IDocsTypes[], void>({
      query: () => ({
        url: '/docs',
        method: 'GET',
      }),
      providesTags: ['Docs'],
    }),
    getDocsById: builder.query<IDocsTypes, string | undefined>({
      query: (id) => ({
        url: `/docs/${id}`,
        method: 'GET',
      }),
    }),
    createDocs: builder.mutation<any, { title: string; description: string }>({
      query: (arg) => ({
        url: '/docs',
        method: 'POST',
        body: {
          title: arg.title,
          description: arg.description,
        },
      }),
      invalidatesTags: ['Docs'],
    }),
    editDocs: builder.mutation<
      any,
      { title: string; description: string; docId: string }
    >({
      query: (arg) => ({
        url: `/docs/${arg.docId}`,
        method: 'PATCH',
        body: {
          title: arg.title,
          description: arg.description,
        },
      }),
      invalidatesTags: ['Docs'],
    }),
    deleteDocs: builder.mutation<any, { docId: string }>({
      query: (arg) => ({
        url: `/docs/${arg.docId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Docs'],
    }),
    getBlocksByHeadId: builder.query<IBlock[], string | undefined>({
      query: (arg) => ({
        url: `blocks/${arg}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Blocks' as const, id })),
              'Blocks',
            ]
          : ['Blocks'],
    }),
  }),
});

export const {
  useGetDocsQuery,
  useGetDocsByIdQuery,
  useGetBlocksByHeadIdQuery,
  useCreateDocsMutation,
  useEditDocsMutation,
  useDeleteDocsMutation,
} = docsApi;
