import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import trackEvent from 'shared/lib/top100TrackEvent';
import Top100Event from 'shared/types/top100';
import { faqLinks } from '../model';

interface FAQDropDownProps {
  changeMenuState: () => void;
}

const FAQDropDown: FC<FAQDropDownProps> = ({ changeMenuState }) => {
  return (
    <Dropdown
      trigger={['hover']}
      menu={{
        items: [
          ...faqLinks.map(
            (link): ItemType => ({
              key: link.to,
              label: (
                <Link
                  target={link?.target || undefined}
                  to={link.to}
                  onClick={() => {
                    changeMenuState();
                  }}
                  data-ord-attribute={link.linkText}
                >
                  {link.linkText}
                </Link>
              ),
            }),
          ),
        ],
      }}
    >
      <Link
        to="/knowledge-base"
        className="header-link"
        onClick={() => {
          trackEvent(Top100Event.NAV_BASE);
          changeMenuState();
        }}
        data-ord-attribute="knowledgeBaseLink"
      >
        База знаний
      </Link>
    </Dropdown>
  );
};

export default FAQDropDown;
