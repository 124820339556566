import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import {
  useCanonical,
  useCrawlerController,
  useInfoController,
} from './controllers';

interface SEOControllerProps {
  metaTitle?: string;
  metaDescription?: string;
  metaKeyWords?: string;
}

const SEOController: FC<SEOControllerProps> = (props) => {
  const { metaTitle, metaDescription, metaKeyWords } = props;
  // meta tags use effect
  useInfoController({ metaTitle, metaDescription, metaKeyWords });
  // crawler controller
  useCrawlerController();
  // canonical
  useCanonical();
  return <Outlet />;
};

export default SEOController;
